import {useEffect, useState} from "react";
import {gql, useApolloClient} from "@apollo/client";
import _ from "lodash"

const OPEN_MUTATION = gql`
    mutation openMelds($melds: PlayMeldsInput!){
        openMelds(input: $melds)
    }
`

const FINALIZE_MUTATION = gql`
    mutation finalize($gameId:String! $playerId:String!){
        finalize(gameId:$gameId playerId:$playerId)
    }
`

const UNDO_MUTATION = gql`
    mutation undo($gameId:String! $playerId:String!) {
        undo(gameId:$gameId playerId:$playerId)
    }
`

const DRAW_DECK_MUTATION = gql`
    mutation draw($gameId:String! $playerId:String!) {
        draw(gameId:$gameId playerId:$playerId)
    }
`

const DRAW_DISCARD_MUTATION = gql`
    mutation drawDiscard($gameId:String! $playerId:String!) {
        drawDiscard(gameId:$gameId playerId:$playerId)
    }
`

const DISCARD_MUTATION = gql`
    mutation discard($gameId:String! $playerId:String! $card:CardInput!){
        discard(gameId:$gameId playerId:$playerId card:$card)
    }
`

const SCORE_CARDS_QUERY = gql`
    query scoreCards($cards:[CardInput!]!) {
        scoreCards(cards:$cards)
    }
`

const PLAY_ON_MELD_MUTATION = gql`
    mutation playCards($input:PlayCardsInput!){
        playCards(input:$input)
    }
`


function MeldInfo({meldStats, meldId, onClick}) {
    return (
        <span key={meldId} id={meldId} className={`meld${meldStats.isClosed ? " meld_complete" : ""}`}
              onClick={onClick}>
            <div className={`meld_rank haf_mymeld_size_medium`}>
                <div
                    className={`cardsprite meldcard meld_${meldStats.isClean ? "clean" : "dirty"}_${meldStats.pileOf}`}/>
            </div>
            <span className={"meld_numbers meld_hide_when_done"}>
                <span className={"meld_rank_count"} id={`meld_rank_count_${meldId}`}>{meldStats.numCards}</span>
                <span className={"meld_wild_count"} id={`meld_wild_count_${meldId}`}>{meldStats.numWilds}</span>
            </span>
        </span>
    )
}


function PlayerInfo({teamId, playerInfo, playerNum}) {
    return (
        <div className={"teammember"} style={{
            color: "#ff0000"
        }} id={`${teamId}_player_${playerNum}`}>
            <div style={{
                display:"flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{display:"flex",flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{flex: 1, color: "#ff0000"}}>{playerInfo.name}</div>
                    <div style={{flex: 1}} id={`${teamId}_handsize_${playerNum}`}>{playerInfo.numCards}</div>
                </div>
                <div id={`${teamId}_playerhandicon_${playerNum}`} style={{flex: 1, fontSize: 24}}>
                    {`${playerInfo.isInFoot ? "🦶" : playerInfo.isInMouth ? "👄" : "🤚"}`}
                </div>
            </div>
        </div>
    )
}

const ENUM_TO_SORT_ORDER = {
    "FOUR": 4,
    "FIVE": 5,
    "SIX": 6,
    "SEVEN": 7,
    "EIGHT": 8,
    "NINE": 9,
    "TEN": 10,
    "JACK": 11,
    "QUEEN": 12,
    "KING": 13,
    "ACE": 14,
    "TWO": 15,
    "JOKER": 16,
    "THREE": 17
}

function TeamInfo({teamData, newMeldFunc, tempMelds, finalizeFunc, enabled, playCardsFunc}) {
    const teamId = teamData.teamId
    const displayMelds = teamData.melds.concat(tempMelds).sort(compareMelds)
    return (
        <div id={"mymelds_wrap"}>
            <div id={"mymelds_info"}>
                <h3 id={"mymelds_label"} style={{marginLeft: "10px"}}>
                    <span className={"to_translate"}>Your melds</span>
                </h3>
                <div className={"teaminfo"} id={teamId}>
                    <div id={`${teamId}_label`} className={"playertablename"} style={{color: "#ff0000"}}>
                        <span>{teamData.teamName || teamData.teamId}</span>
                    </div>
                    <div className={"teamnames"} id={`teamnames_${teamId}`}>
                        <PlayerInfo teamId={teamId} playerInfo={teamData.player1Info} playerNum={1}/>
                        <PlayerInfo teamId={teamId} playerInfo={teamData.player2Info} playerNum={2}/>
                    </div>
                </div>
                <div id={`scores_${teamId}`} className={"haf_scores"}>
                <span className={"haf_total_score_label"} id={`game_score_${teamId}`}>
                    <span className={"to_translate"}>Game score</span>:
                </span>
                    <span className={"haf_total_score"} id={"total_score_2"}>{teamData.score}</span>
                </div>
            </div>
            <div id={`playermelds_${teamId}`} className={"playermelds whiteblock"}>
                <span id={"new_meld"} className={"meld new_meld_button"} style={{padding: "5px"}}>
                    <div className={"meld_rank"} style={{cursor: enabled ? "pointer" : "default"}}>
                        <span className={"meldnewmeld"} id={"new_meld_button"} onClick={() => {
                            if (enabled) {
                                newMeldFunc()
                            }
                        }}>New Meld</span>
                    </div>
                    {teamData.melds.length === 0 && displayMelds.length !== 0 && <div className={"meld_rank"}>
                        <span className={"meldnewmeld"} id={"play_opening_button"}
                              onClick={finalizeFunc}>Finalize</span>
                    </div>}
                </span>
                {displayMelds.map(it => <MeldInfo key={it.id} meldStats={it.meldStats} meldId={it.id}
                                                  onClick={() => playCardsFunc(it.id)}/>)}
            </div>
        </div>
    )
}

function PlayerHand({hand, selectCardFunc, selectedCards, computeScoreFunc}) {
    const [computedScore, setComputedScore] = useState(0)
    useEffect(() => {
        computeScoreFunc(hand.filter(it => selectedCards.includes(it.id)).map(it => ({
            number: it.number,
            suit: it.suit,
            id: it.id
        }))).then(({data}) => {
            console.log({data})
            setComputedScore(data.scoreCards)
        })
    })

    return (<div id={"myhand_wrap"}>
        <div id={"myhand_labels"} style={{fontWeight: "bold"}}>
						<span style={{marginLeft: "10px"}}>
							<span className={"to_translate"}>Your hand</span>
						</span>
            <div id={"myhand_selscore_box"}>
                <span>Selection score:</span>
                <span id={"myhand_selscore_counter"}>{computedScore}</span>
            </div>
            <div style={{visibility: "hidden"}}/>
        </div>
        <div id={"myhand_outer"} className={"whiteblock pulsing_border"}>
            <div id={"myhand"} style={{position: "relative", display: "flex"}}>
                {hand.map((it, index) =>
                    (<div key={it.id}
                          className={`stockitem${selectedCards.includes(it.id) ? " stockitem_selected" : ""}`}
                          style={{
                              position: "relative",
                              top: "0px",
                              left: `${index * 60}px`,
                              width: "0px",
                              height: "184px",
                              zIndex: index + 2,
                              opacity: 1
                          }}
                          onClick={() => {
                              selectCardFunc(it.id)
                          }}>
                        <div className={`cardsprite halfheightcard ${it.suit}_${it.number}`}/>
                    </div>)
                )}
            </div>
        </div>
    </div>)
}

function compareMelds(a, b) {
    const x = ENUM_TO_SORT_ORDER[a.meldStats.pileOf]
    const y = ENUM_TO_SORT_ORDER[b.meldStats.pileOf]
    if (a.meldStats.isClosed && !b.meldStats.isClosed) {
        return 1
    }
    if (!a.meldStats.isClosed && b.meldStats.isClosed) {
        return -1
    }
    return x > y ? 1 : (x < y ? -1 : 0)
}


function OpponentInfo({opponentData}) {
    return opponentData.map(it => {
        return (
            <div key={it.teamId} className={"opponent"} id={`opponent_${it.teamId}`}>
                <div className={"opponentteaminfo"} id={`opponentteaminfo_${it.teamId}`}>
                    <div>
                        <div id={`teaminfo_${it.teamId}_label`} className={"playertablename"}
                             style={{color: "#7bab27"}}>{it.teamName}</div>
                        <div className={"teamnames"} id={`teamnames_${it.teamId}`}>
                            <PlayerInfo playerNum={1} playerInfo={it.player1Info} teamId={it.teamId}/>
                            <PlayerInfo playerNum={2} playerInfo={it.player2Info} teamId={it.teamId}/>
                        </div>
                    </div>
                    <div id={"scores_1"} className={"haf_scores"}>
                        <span className={"haf_total_score_label"} id={`game_score_${it.teamId}`}>
                            <span className={"to_translate"}>Game score</span>:
                        </span>
                        <span className={"haf_total_score"} id={`total_score_${it.teamId}`}>{it.score}</span>
                    </div>
                </div>
                <div className={"playermelds whiteblock"} id={`playermelds_${it.teamId}`}>
                    {it.melds.sort(compareMelds).map(it => <MeldInfo meldStats={it.meldStats} meldId={it.id}/>)}
                </div>
            </div>
        )
    })
}

function CommonArea({
                        gameStateInfo: {
                            roundNumber,
                            minOpeningScore,
                            deckSize,
                            topCard,
                            discardSize,
                            activePlayerName: {name: currentPlayer}
                        },
                        drawCardFunc,
                        drawDiscardFunc,
                        canDiscard,
                        discardFunc,
                        canDraw,
                        canDrawDiscard,
                        enabled,
                        isOpening,
                        selectedScore,
                        undoFunc
                    }) {
    return (
        <div id={"tablecards"} className={"whiteblock"}>
            <div id={"table_info_1"} className={"table_info_block"}>
                <div id={"gameheader"}>
                    <div id={"help_button_spacer"}/>
                    <div id={"game_box"} className={"game_box"}/>
                    <div id={"help_button"} className={"helpicon"}/>
                </div>
                <div className={"round_info"}>
                    <div id={"round_info"}>
                        <span id={"current_player_label"} className={"round_info_text"}>Player: </span>
                        <div id={"current_player"}>{currentPlayer}</div>
                        <br/>
                        <span id={"round_number_label"} className={"round_info_text"}>Round: </span>
                        <div id={"round_number"}>{roundNumber}</div>
                        <br/>
                        <span id={"round_minimum_label"} className={"round_info_text"}>To open: </span>
                        <span id={"round_minimum"}>{minOpeningScore}</span>
                    </div>
                    {/*<div id={"target_contract"} className={"target_contract"}>
                            <div>
							<span style={{marginLeft: "10px"}}>
								<span className={"to_translate"}>Target contract</span>:
							</span>
                                <div id={"target_contractbar"} className={"contractbar contract2"}>
                                    <span className={"contractsection contractsection_any"}/>
                                    <span className={"contractsection contractsection_any"}/>
                                </div>
                            </div>
                        </div>*/}
                </div>
            </div>
            <div id={"table_info_2"} className={"table_info_block"}>
                <div id={"round_to_go_box"}
                     style={{display: "inline-block", visibility: isOpening ? "visible" : "hidden"}}>
                    <span id={"round_to_go_label"} className={"round_info_text"}>Still needed to open: </span>
                    <div
                        id={"round_to_go"}>{selectedScore < minOpeningScore ? minOpeningScore - selectedScore : 0}</div>
                </div>
                <div id={"haf_undo_button"} onClick={undoFunc}
                     style={{display: "inline-block", visibility: isOpening ? "visible" : "hidden"}}>Undo
                </div>
            </div>
            <div id={"table_info_3"} className={"table_info_block"}>
                <div id={"card_piles"} className={"card_piles"}>
                    <div id={"stock_wrap"} className={"carddeck_wrap"}>
                        <div id={"stocklabel"} className={"cardcounter"}>
                            <span className={"to_translate"}>Draw</span>
                        </div>
                        <div id={"stock_hl"} className={`${enabled && canDraw && "pulsing_bg"}`}>
                            <div id={"stock_clip"} className={"carddeck halfheightcard_wrap"}>
                                <div id={"stock"} className={"cardsprite card_back halfheightcard"}
                                     onClick={() => {
                                         if (enabled && canDraw) {
                                             drawCardFunc()
                                         }
                                     }} style={{cursor: canDraw && enabled ? "pointer" : "default"}}/>
                            </div>
                        </div>
                        <div id={"stockcounter"} className={"cardcounter"}>{deckSize}</div>
                    </div>
                    <div id={"discard_wrap"} className={"carddeck_wrap"}>
                        <div id={"stocklabel"} className={"cardcounter"}>
                            <span className={"to_translate"}>Discard</span>
                        </div>
                        <div id={"discard_hl"}
                             className={`${enabled && (canDrawDiscard || canDiscard) && "pulsing_bg"}`}>
                            <div id={"discard_clip"} className={"carddeck"}>
                                <div id={"discarded_card"}
                                     className={`${!!topCard ? "cardsprite" : "blankcard"} ${!!topCard && `${topCard.suit}_${topCard.number}`} halfheightcard`}
                                     onClick={(() => {
                                         if (enabled && canDrawDiscard) {
                                             drawDiscardFunc()
                                         } else if (enabled && canDiscard) {
                                             discardFunc()
                                         }
                                     })}
                                     style={{cursor: enabled && (canDrawDiscard || canDiscard) ? "pointer" : "default"}}/>
                            </div>
                        </div>
                        <br/>
                        <div id={"discardcounter"} className={"cardcounter"}>{discardSize}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function WholeTable({gameStateInfo, gameId}) {
    const client = useApolloClient()
    const myPlayerData = gameStateInfo.myPlayerData
    const isItMyTurn = !!gameStateInfo.activePlayerName.id
    const canDraw = !gameStateInfo.playerHasDrawn
    const [selectedCards, setSelectedCards] = useState([])
    const tempMelds = myPlayerData.playedMelds
    const selectCard = (id) => {
        if (selectedCards.includes(id)) {
            setSelectedCards(selectedCards.filter(it => it !== id))
        } else {
            setSelectedCards(selectedCards.concat(id))
        }
    }
    const playerId = myPlayerData.id
    const myTeamId = myPlayerData.teamId;
    console.log("TEAMID:" + myTeamId)
    const otherTeamData = gameStateInfo.teamData.filter(it => it.teamId !== myTeamId)
    const myTeamData = gameStateInfo.teamData.filter(it => it.teamId === myTeamId)[0]
    const canDrawDiscard = canDraw && myTeamData.melds.length !== 0 && gameStateInfo.discardSize >= 5 && gameStateInfo.topCard.suit !== "WILD"
        && gameStateInfo.topCard.number !== "TWO"
        && (gameStateInfo.topCard.number !== "THREE" || (gameStateInfo.topCard.suit !== "SPADE" && gameStateInfo.topCard.suit !== "HEART"))
        && myPlayerData.hand.filter(it => gameStateInfo.topCard.number === it.number).length >= 2
    const displayHand = myPlayerData.hand
    const newMeld = () => {
        const cards = selectedCards.map(id => displayHand.filter(it => it.id === id)).flat()
        const inputCards = cards.map(it => {
            return {suit: it.suit, number: it.number, id: it.id}
        })
        client.mutate({
            mutation: OPEN_MUTATION,
            variables: {
                melds: {gameId, playerId, melds: [inputCards]}
            }
        }).then(({data}) => {
            console.log(data)
            if (data.openMelds === "Success") setSelectedCards([])
        })
    }
    const finalize = () => {
        client.mutate({
            mutation: FINALIZE_MUTATION,
            variables: {
                gameId,
                playerId
            }
        }).then(({data}) => {
            console.log(data)
            if (data.finalize === "Success") setSelectedCards([])

        })
    }
    const undo = () => {
        client.mutate({mutation: UNDO_MUTATION, variables: {gameId, playerId}}).then(({data}) => {
            console.log(data.undo)
        })
    }

    const drawCard = () => {
        client.mutate({mutation: DRAW_DECK_MUTATION, variables: {gameId, playerId}}).then(({data}) => {
            console.log(data.draw)
        })
    }

    const drawDiscard = () => {
        client.mutate({mutation: DRAW_DISCARD_MUTATION, variables: {gameId, playerId}}).then(({data}) => {
            console.log(data.drawDiscard)
        })
    }

    const discard = () => {
        if (selectedCards.length !== 1) {
            console.log("error")
        } else {
            const card = displayHand.filter(it => selectedCards[0] === it.id)[0]
            setSelectedCards([])
            console.log(card)
            client.mutate({
                mutation: DISCARD_MUTATION,
                variables: {gameId, playerId, card: {number: card.number, suit: card.suit, id: card.id}}
            }).then(({data}) => {
                console.log(data.discard)
            })
        }
    }

    const computeScore = (cards) => {
        console.log({query: SCORE_CARDS_QUERY, variables: {cards: cards}})
        return client.query({query: SCORE_CARDS_QUERY, variables: {cards: cards}})
    }


    const playCards = (meldId) => {
        if (selectedCards.length !== 0) {
            const cards = displayHand.filter(card => selectedCards.includes(card.id)).map(it => ({
                suit: it.suit,
                number: it.number,
                id: it.id
            }))
            client.mutate({
                mutation: PLAY_ON_MELD_MUTATION,
                variables: {input: {gameId, playerId, meldId, cards}}
            }).then(({data}) => {
                console.log(data)
                if (data.playCards !== "Success") {
                    //alert user
                } else {
                    setSelectedCards([])
                }
            })
        }
    }
    console.log(tempMelds)
    const playedScore = _.sum(tempMelds.map(it => it.meldStats.cardScore))
    console.log(playedScore)
    return (
        <div id={"wholetable"} style={{margin: "0px 20px"}}>
            <CommonArea enabled={isItMyTurn} gameStateInfo={gameStateInfo} undoFunc={undo} drawCardFunc={drawCard}
                        discardFunc={discard} canDrawDiscard={canDrawDiscard} canDraw={canDraw}
                        drawDiscardFunc={drawDiscard}
                        canDiscard={!canDraw && selectedCards.length === 1 && tempMelds.length === 0}
                        isOpening={tempMelds.length !== 0} selectedScore={playedScore}/>
            <PlayerHand hand={displayHand} selectCardFunc={selectCard} selectedCards={selectedCards}
                        computeScoreFunc={computeScore}/>
            <TeamInfo enabled={isItMyTurn && !canDraw} teamData={myTeamData} newMeldFunc={newMeld} tempMelds={tempMelds}
                      finalizeFunc={finalize} playCardsFunc={playCards}/>
            <OpponentInfo opponentData={otherTeamData}/>
            <div id={"melds_placeholder"} style={{display: "none"}}/>
        </div>
    )
}

export default WholeTable
