import ReactList from "react-list"
import * as React from "react";
import {useState} from "react";

async function startGame(id, mutation) {
    mutation({variables: {gameId: id}})
}

function Game({id, isFull, isStarted, numPlayers, numSeats, teamDetails, joinGame, visible, hasMouthExtension}) {
    const [playerName, setPlayerName] = useState("")
    const [playerSecret, setPlayerSecret] = useState("")
    const [selectedTeam, setSelectedTeam] = useState("NEW_TEAM")

    return (
        <div style={{marginTop: "15px", display: 'flex', flexDirection: 'row'}}>
            <div style={{width: "300px"}}>
                {"players: " + teamDetails.map(it => it.names).join()} <br/>
                {"isFull: " + isFull} <br/>
                {"isStarted: " + isStarted} <br/>
                {"numPlayers: " + numPlayers} <br/>
                {"numSeats: " + numSeats} <br/>
                {"hasMouthExtension:" + hasMouthExtension} <br/>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <select defaultValue={"NEW_TEAM"} onChange={({target: {value}}) => {
                        setSelectedTeam(value)
                    }}>
                        <option value={"NEW_TEAM"}>New Team</option>
                        <option value={""}>Random</option>
                        {teamDetails.map(it => {
                            return (
                                <option key={it.teamId} value={it.teamId}>{it.names}</option>
                            )
                        })}
                    </select>
                    <input placeholder={"Player Name"} type={"text"} style={{height: "15px"}}
                           value={playerName}
                           onChange={({target: {value}}) => setPlayerName(value)}/>
                    <input placeholder={"Player Secret"} type={"text"} style={{height: "15px"}}
                           value={playerSecret}
                           onChange={({target: {value}}) => setPlayerSecret(value)}/>
                </div>
                <button onClick={() => joinGame(id, playerName, playerSecret, selectedTeam)}>Join</button>
            </div>
        </div>
    )
}


function GameList({joinGame, data, loading, error, visible}) {
    let gameComponents = []
    if (!loading && !error) gameComponents = data.gameList.map(it => (
        <Game visible={visible} key={it.id} id={it.id} isFull={it.isFull} isStarted={it.isStarted}
              numPlayers={it.numPlayers}
              numSeats={it.numSeats}
              teamDetails={it.teamDetails}
              hasMouthExtension={it.hasMouthExtension}
              mutation={startGame}
              joinGame={joinGame}
        />))
    const renderer = (index, key) => gameComponents[index]
    return (
        <div style={{flex: 1, alignSelf: "center", display: visible ? "flex" : "none"}}>
            {loading && "Loading..."}
            {!loading && !error && <ReactList itemRenderer={renderer} length={gameComponents.length} type='uniform'/>}
            {!loading && error && JSON.stringify(error)}
        </div>
    )

}

export default GameList
