import './App.css';
import React, {useEffect, useState} from 'react';

import {
    ApolloClient,
    ApolloProvider,
    gql,
    HttpLink,
    InMemoryCache,
    split,
    useApolloClient,
    useSubscription
} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities'
import WholeTable from "./WholeTable";
import GameList from "./GameList";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";

const httpLink = new HttpLink({
    uri: 'https://hnf.undermark5.com/graphql'
});

const wsLink = new GraphQLWsLink(
    createClient({
        url: 'wss://hnf.undermark5.com/subscriptions',
        retryAttempts: 100,
        keepAlive: 5000,
    })
);


const splitLink = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

const client = new ApolloClient({
    uri: "https://hnf.undermark5.com/graphql",
    cache: new InMemoryCache(),
    link: splitLink
})

const ROUND_SUMMARY = gql`
    query roundSummary($gameId:String! $roundNumber:Int!){
        getRoundSummary(gameId:$gameId roundNumber:$roundNumber)
    }
`

const GAME_LIST_SUB = gql`
    subscription gameList{
        gameList {
            id
            isFull
            isStarted
            numSeats
            numPlayers
            hasMouthExtension
            teamDetails{
                names
                teamId
            }
        }
    }
`

const START_GAME = gql`
    mutation startGame($gameId: String!){
        startGame(gameId: $gameId)
    }
`

// const debugGame = gql`
//     mutation debug{
//         debug
//     }
// `

const NEW_GAME = gql`
    mutation newGame($numPlayers: Int!, $hasMouthExtension: Boolean! = false) {
        createGame(numSeats:$numPlayers hasMouthExtension:$hasMouthExtension)
    }
`

const JOIN_GAME = gql`
    mutation joinGame($input:JoinGameInput!){
        joinGame(input:$input)
    }
`

const REJOIN_GAME = gql`
    mutation rejoinGame($input:RejoinGameInput!){
        rejoinGame(input:$input)
    }
`

const GAME_STATE_SUB = gql`
    subscription gameState($input:GameStateSubscriptionInput!){
        gameState(input: $input) {
            gameStateInfo {
                hasMouthExtension
                minOpeningScore
                roundNumber
                discardSize
                deckSize
                playerHasDrawn
                activePlayerName {
                    name
                    ... on PrivatePlayerInfo {
                        id
                    }
                }
                topCard {
                    suit
                    number
                }
                teamData {
                    teamId
                    score
                    teamName
                    player1Info {
                        name
                        numCards
                        teamId
                        isInFoot
                        isInMouth
                    }
                    player2Info {
                        name
                        numCards
                        teamId
                        isInFoot
                        isInMouth
                    }
                    melds {
                        id
                        meldStats {
                            pileOf
                            isClean
                            numCards
                            numWilds
                            isClosed
                        }
                    }
                }
                myPlayerData {
                    hand {
                        number
                        suit
                        id
                    }
                    playedMelds {
                        id
                        meldStats {
                            pileOf
                            isClean
                            numCards
                            numWilds
                            cardScore
                            isClosed
                        }
                    }
                    isInFoot
                    isInMouth
                    id
                    teamId
                }
            }
            gameId
        }
    }
`

function GameSubscription({gameId, playerId, teams, numPlayers, maxPlayers, startGame}) {
    const client = useApolloClient()
    const {data, loading, error} = useSubscription(GAME_STATE_SUB, {
        variables: {
            input: {
                gameId: gameId,
                playerId: playerId
            }
        }
    })
    if (error) {
        console.log("SUBSCRIPTION ERROR:")
        console.log(error)
    }
    const [roundNumber, setRoundNumber] = useState(data ? data.gameState.gameStateInfo.roundNumber : 1)
    useEffect(() => {
        if (!loading && data && data.gameState.gameStateInfo.roundNumber) {
            if (roundNumber !== data.gameState.gameStateInfo.roundNumber) {
                console.log("NEW ROUND")
                client.query({
                    query: ROUND_SUMMARY,
                    variables: {gameId: data.gameState.gameId, roundNumber: data.gameState.gameStateInfo.roundNumber}
                }).then(({data}) => {
                    const result = JSON.parse(data.getRoundSummary)
                    if (result.success) {
                        console.log("SUCCESS")
                        console.log(result.message)
                    } else {
                        console.log("ERROR")
                        console.log(result.message)
                    }
                })
            }
            setRoundNumber(data.gameState.gameStateInfo.roundNumber)
        }
    }, [data, loading, client, roundNumber])
    return (<div style={{alignSelf: loading ? "center" : "normal"}}>
        {!loading && <div>
            <WholeTable gameStateInfo={data.gameState.gameStateInfo} gameId={gameId}/>
        </div>}
        {loading && <div style={{alignSelf: "center"}}>
            <div>{numPlayers !== maxPlayers ? "Waiting for other players to join..." : "Waiting for game to start..."}</div>
            <div>DO NOT CLICK REFRESH OR CLOSE THIS PAGE</div>
            <br/>
            {teams && teams.map((it, index) => {
                return (
                    <div key={`team_${index}`} id={`team_${index}`}>
                        {`Team ${index + 1}: ${it.names}`}
                    </div>)
            })}
            {numPlayers === maxPlayers && <button onClick={() => startGame(gameId)}>Start</button>}
        </div>}
    </div>)
}

function HandAndFoot() {
    const [state, setState] = useState({isSubscribed: false, subGameId: "", playerId: ""})
    const {loading, error, data} = useSubscription(GAME_LIST_SUB)

    const subbedData = data && data.gameList.filter(it => it.id === state.subGameId)[0]
    const client = useApolloClient()
    const startGame = (gameId) => {
        client.mutate({mutation: START_GAME, variables: {gameId}}).then(({data}) => {
            console.log(data)
        })
    }
    const newGame = (numPlayers) => {
        client.mutate({mutation: NEW_GAME, variables: {numPlayers}}).then(({data}) => {
            console.log(data)
        })
    }

    const [selectedNumPlayers, setSelectedNumPlayers] = useState(4)

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: state.isSubscribed ? 'center' : 'normal',
            width: '100%',
            height: '100%'
        }}>
            <h1 style={{flex: 1, alignSelf: "center"}}>🤚&🦶</h1>
            <div style={{
                flexDirection: "row",
                flex: 1,
                alignSelf: "center",
                visibility: !state.isSubscribed ? "visible" : "hidden"
            }}>
                <select id={"new_game_menu"} title={"New Game"}
                        onChange={({target: {value}}) => {
                            setSelectedNumPlayers(value - 0)
                        }}>
                    <option value={4}>4 Player</option>
                    <option value={6}>6 Player</option>
                    <option value={8}>8 Player</option>
                </select>
                <button onClick={() => newGame(selectedNumPlayers)}>New Game</button>
            </div>
            <GameList visible={!state.isSubscribed} data={data} error={error} loading={loading}
                      joinGame={(gameId, playerName, playerSecret, selectedTeam) => {
                          const gameInfo = data.gameList.filter(it => it.id === gameId)[0]
                          if (!gameInfo.isStarted) {
                              client.mutate({
                                  mutation: JOIN_GAME,
                                  variables: {input: {gameId, playerName, playerSecret, auto: selectedTeam === "", teamId: (selectedTeam === "NEW_TEAM" || selectedTeam === "") ? null : selectedTeam}}
                              }).then(({data}) => {
                                  const result = JSON.parse(data.joinGame)
                                  if (result.success) {
                                      setState({
                                          isSubscribed: true,
                                          subGameId: gameId,
                                          playerId: result.message
                                      })
                                  }
                              })
                          } else {
                              client.mutate({
                                  mutation: REJOIN_GAME,
                                  variables: {input: {gameId, playerName, playerSecret}}
                              }).then(({data}) => {
                                  const result = JSON.parse(data.rejoinGame)
                                  if (result.success) {
                                      setState({
                                          isSubscribed: true,
                                          subGameId: gameId,
                                          playerId: result.message
                                      })
                                  }
                              })
                          }
                      }}/>
            {state.isSubscribed && subbedData &&
            <GameSubscription maxPlayers={subbedData.numSeats} numPlayers={subbedData.numPlayers}
                              teams={subbedData.teamDetails} startGame={startGame} gameId={state.subGameId}
                              playerId={state.playerId}/>}
        </div>
    )
}

function App() {
    return (
        <ApolloProvider client={client}>
            <HandAndFoot/>
        </ApolloProvider>
    );
}

export default App;
